// Responsiveness
// Responsive mixins from: https://github.com/jgthms/bulma/blob/41a8ca675d8a0036113785252463190a5f343ce3/sass/utilities/mixins.sass#L71
// The container horizontal gap, which acts as the offset for breakpoints
$gap: 64px !default;
// 960, 1152, and 1344 have been chosen because they are divisible by both 12 and 16
$tablet: 768px !default;
// 960px container + 4rem
$desktop: 960px + (2 * $gap) !default;

@mixin from($device) {
  @media screen and (min-width: $device) {
    @content;
  }
}

@mixin until($device) {
  @media screen and (max-width: $device) {
    @content;
  }
}

@mixin mobile {
  @media screen and (max-width: $tablet - 1px) {
    @content;
  }
}

@mixin tablet {
  @media screen and (min-width: $tablet), print {
    @content;
  }
}

@mixin tablet-only {
  @media screen and (min-width: $tablet) and (max-width: $desktop - 1px) {
    @content;
  }
}

@mixin all-mobile {
  @media screen and (max-width: $tablet) {
    @content;
  }
}

@mixin touch {
  @media screen and (max-width: $desktop - 1px) {
    @content;
  }
}

@mixin desktop {
  @media screen and (min-width: $desktop) {
    @content;
  }
}

@mixin desktop-only {
  @media screen and (min-width: $desktop) and (max-width: $widescreen - 1px) {
    @content;
  }
}
