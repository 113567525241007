@tailwind base;
@tailwind components;
@tailwind utilities;

/* Overriding some Tailwind defaults to maintain compatibility with previous styling solution */
@layer base {
  *,
  ::before,
  ::after {
    border-color: black;
  }

  ol,
  ul {
    list-style-type: revert;
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    font-weight: bold;
    margin: revert;
  }

  p {
    margin: revert;
  }

  .typography {
    h2 {
      font-size: 28px;
    }

    h3 {
      font-size: 20px;
      margin: 0 0 16px;
    }
  }
}
