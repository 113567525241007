@import '../scss/mixins';
@import '../scss/variables';

html {
  scroll-behavior: smooth;
}

html,
body {
  padding: 0;
  margin: 0;
  font-family: $cui-font-family-tt-norms;
  color: $cookunity-black;
  min-width: 334px;
}

* {
  box-sizing: border-box;
}

img[width='1'][height='1'] {
  display: none;
}

*:focus {
  outline: none;
}

section {
  padding: 50px 0;

  @include mobile {
    padding: 30px 0;
  }

  .content {
    max-width: 400px;
    margin: 10px;
  }
}

a {
  text-decoration: none;
  color: inherit;
}

a:hover {
  text-decoration: underline;
}

img {
  max-width: 100%;
  display: block;
}

a.button {
  font-weight: 800;
}

h1,
h2,
h3,
h4,
h6 {
  font-family: $cui-font-family-tt-norms;
  font-style: normal;
  font-weight: bold;
  line-height: 1.2em;
  margin-bottom: 10px;

  span {
    color: $cookunity-green;
  }
}

h1 {
  font-size: 36px;

  @include mobile {
    font-size: 28px;
  }
}

h2 {
  font-size: 28px;
}

h3 {
  font-size: 20px;
  margin: 0 0 16px;
}

h4 {
  font-size: 16px;
}

h5 {
  font-size: 18px;
  text-transform: uppercase;
}

h6 {
  font-size: 18px;
}

p {
  font-size: 16px;
  line-height: 1.5em;

  &.small {
    font-size: 14px;
  }
}

.h2-weight {
  font-family: $cui-font-family-tt-norms;
  font-size: 40px;
  font-weight: 400;
  margin: 0;

  @include mobile {
    font-size: 30px;
  }

  span {
    font-weight: 800;
  }
}

.skeleton-row {
  background: url(/assets/images/our-menu/circle-transparent.gif) no-repeat
    center center;
  background-size: 300px;
  width: 100%;
  height: 440px;
}

.container {
  margin: 0 auto;
  width: 100%;
  max-width: 1200px;
  padding-left: 15px;
  padding-right: 15px;

  .row {
    display: flex;
    flex-wrap: wrap;
    margin: 0 -15px;

    > .col {
      flex: 1 1;
      margin: 0 15px;

      @include mobile {
        margin: 0 auto;
      }
    }

    &.reverse {
      flex-direction: row-reverse;
    }

    @include mobile {
      margin: 0;
      flex-direction: column;

      &.reverse,
      &.mobile-reverse {
        flex-flow: column-reverse;
      }
    }
  }
}

.grid {
  display: grid;
  grid-column-gap: 10px;
  grid-row-gap: 10px;
}

.col-40 {
  width: 40%;
}

.col-50 {
  width: 50%;
}

.col-60 {
  width: 60%;
}

.col-40,
.col-50,
.col-60 {
  @include mobile {
    width: 100% !important;
  }
}

.text-center {
  text-align: center;
}

.margin-center {
  margin: 0 auto;
}

.section-margin {
  margin: 14px 0 40px;
}

.columns {
  display: flex;
  flex-direction: row;

  @include mobile {
    flex-direction: column;
  }
}

input {
  padding: 10px 20px;
  border-radius: 30px;
  border: 1px solid $cui-black-color-300;
}

.input-error {
  border: 1px solid $cookunity-red;
}

button,
.btn {
  font-family: $cui-font-family-tt-norms;
  background: $cui-black-color;
  border-radius: 50px;
  border: none;
  color: $cookunity-white;
  padding: 10px 20px;
  font-weight: 800;
  cursor: pointer;
}

button.btn-border {
  border-radius: 200px;
  border: 1px solid $cui-black-color-200;
  color: $cui-black-color;
}

button:hover,
.btn:hover {
  text-decoration: none;
}

.text-reverse {
  text-align: right;
}

.text-green {
  color: $cookunity-green;
}

.text-yellow {
  color: $cookunity-yellow;
}

.text-pink {
  color: $cookunity-pink;
}

.text-blue {
  color: $cookunity-blue;
}

.only-mobile {
  display: none;

  @include mobile {
    display: initial;
  }
}

.only-desktop {
  display: initial;

  @include mobile {
    display: none;
  }
}

.video-iframe-responsive {
  position: relative;
  padding-bottom: 56.25%;
  padding-top: 25px;
  height: 0;

  iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
}

.section-terms {
  margin: 72px auto 99px;
  text-align: center;
  color: $cookunity-gray-2;
  padding: 29px 0;
  border-top: 1px solid $cui-black-color-200;
  border-bottom: 1px solid $cui-black-color-200;
  text-transform: none;
}

#privacyPolicy,
#terms {
  .row {
    margin-bottom: 60px;
  }
}

.text-align-left-center {
  text-align: left;

  @include mobile {
    text-align: center;
  }
}

.p-sub-text {
  width: 74%;
  text-align: left;

  @include mobile {
    width: 100%;
  }
}

.p-sub-text-center {
  width: 74%;
  margin: 0 auto;

  @include mobile {
    width: 100%;
  }
}

.yellow-span {
  color: $cui-yellow-color-500;
}

.subtitle {
  text-align: center;
  margin: 0;
}
