$cookunity-black: #231f20;
$cookunity-white: #ffffff;
$cookunity-green: #7ad7a7;

$cookunity-yellow: #ffe06c;
$cookunity-pink: #fa9cad;
$cookunity-light-green: #a9d273;
$cookunity-blue: #59c6e7;
$cookunity-dark-green: #47d178;
$cookunity-red: #ee5744;

$cookunity-gray-1: #4d4d4f;
$cookunity-gray-2: #999999;
$cui-gray-color-100: #fcfcfc;
$cui-gray-color-border: #d1d1d6;

///// rebranding 22

$cui-black-color: #000;
$cui-black-color-50: #fafafa;
$cui-black-color-100: #f5f5f5;
$cui-black-color-150: #eee;
$cui-black-color-200: #ddd;
$cui-black-color-250: #f8f8f8;
$cui-black-color-300: #a8a8a8;
$cui-black-color-350: #707070;
$cui-black-color-400: #4d4d4f;

$cui-black-color-600: #231f20;
$cui-black-color-700: #010101;
$cui-black-color-800: #212121;
$cui-black-color-850: #26272b;

$cui-black-color-shadow: rgba(0, 0, 0, 0.08);

$cui-yellow-color-500: #ffc84e;
$cui-yellow-color-600: #ffb81c;
$cui-yellow-color-550: #f2a802;

$cui-wine-color-300: #bd4e83;
$cui-wine-color-500: #97225a;
$cui-wine-color-700: #6d1a42;

$cui-green-color-100: #beecd8;
$cui-olive-green-color-200: #d4e9b9;
$cui-olive-green-color-500: #199861;
$cui-olive-green-color-700: #0d6d44;

$cui-light-blue-200: #c7c7fb;
$cui-light-blue-700: #3939d8;
$cui-light-blue-900: #121286;

$cui-pink-200: #f8c2e0;
$cui-pink-300: #f68ac6;
$cui-pink-500: #da1884;

$cui-gold-500: #c7b273;
$cui-bg-darkgray: #1c1c1c;

// Font Families
$cui-font-family-tt-norms: var(--font-tt-norms);
$cui-font-family-rossanova: var(--font-rossanova);
$cui-font-family-avallon: var(--font-avallon);
$cui-font-family-reinata: var(--font-reinata);

// Fonts Sizes
$cui-font-xxs: 10px;
$cui-font-xs: 12px;
$cui-font-sm: 14px;
$cui-font-base: 16px;
$cui-font-lg: 18px;
$cui-font-xl: 20px;
$cui-font-2xl: 24px;
$cui-font-3xl: 28px;
$cui-font-4xl: 36px;
$cui-font-5xl: 40px;
$cui-font-6xl: 48px;
$cui-font-7xl: 52px;
$cui-font-8xl: 60px;

// Line height
$cui-line-height-xxs: 10px;
$cui-line-height-xs: 12px;
$cui-line-height-sm: 14px;
$cui-line-height-base: 16px;
$cui-line-height-lg: 18px;
$cui-line-height-xl: 20px;
$cui-line-height-2xl: 24px;
$cui-line-height-3xl: 28px;
$cui-line-height-4xl: 36px;
$cui-line-height-5xl: 40px;
$cui-line-height-6xl: 48px;
$cui-line-height-7xl: 56px;
$cui-line-height-8xl: 60px;

// Font weight
$cui-font-normal: 400; // normal = regular
$cui-font-bold: 700;
$cui-font-extrabold: 800;

// Buttons
$cui-font-button-small: $cui-font-sm;
$cui-space-button-small: 7px 16px;
$cui-height-button-small: 34px;

$cui-font-button-medium: $cui-font-sm;
$cui-space-button-medium: 9px 24px;
$cui-height-button-medium: 38px;

$cui-font-button-large: $cui-font-base;
$cui-space-button-large: 12px 32px;
$cui-height-button-large: 48px;

// Spaces
$cui-gutter-small: 4px;
$cui-gutter: 8px;
$cui-gutter-medium: 12px;
$cui-gutter-large: 16px;

// ZIndex
$z-0: 0;
$z-10: 10;
$z-20: 20;
$z-30: 30;
$z-40: 40;
$z-50: 50;
$z-auto: auto;

// Cards
$cui-card-border-radius: 8px;

// Tags
$cui-font-tag-xsmall: $cui-font-xs;
$cui-font-tag-small: $cui-font-sm;
$cui-font-tag-medium: $cui-font-base;
$cui-font-tag-large: $cui-font-lg;

$cui-space-tag-xsmall: 2px;
$cui-space-tag-small: 4px;
$cui-space-tag-medium: 8px;
$cui-space-tag-large: 12px;

// Border radius
$cui-border-radius-xxs: 2px;
$cui-border-radius-sm: 4px;
$cui-border-radius-md: 8px;
$cui-border-radius-lg: 12px;
$cui-border-radius-xl: 16px;
$cui-border-radius-xxl: 20px;
