@import '../scss/mixins';
@import '../scss/variables';

section.rebrand {
  padding: 0;
}

h1.rebrand,
h2.rebrand.lead {
  font-family: $cui-font-family-rossanova;
  font-size: 64px;
  margin-bottom: 76px;
  line-height: 1em;

  @include mobile {
    font-size: $cui-font-4xl;
    line-height: 44px;
    margin-bottom: 30px;
  }
}

h1.rebrand span {
  font-family: $cui-font-family-avallon;
  color: $cui-yellow-color-500;
  display: inline;
  font-size: 90px;
  line-height: 100%;
  letter-spacing: -0.02em;

  @include mobile {
    font-size: 50px;
  }
}

h2.rebrand {
  font-family: $cui-font-family-rossanova;
  font-size: $cui-font-8xl;
  line-height: 68px;
  font-weight: $cui-font-bold;

  @include mobile {
    font-size: $cui-font-4xl;
    line-height: 44px;
  }
}

h2.small {
  font-size: 28px;
  line-height: 36px;
}

h3.rebrand {
  font-family: $cui-font-family-tt-norms;
  font-weight: $cui-font-bold;
  font-size: $cui-font-xl;
  line-height: $cui-line-height-3xl;
}

h3.rebrand.lead {
  font-size: 36px;
  line-height: 44px;
}

ul.rebrand li {
  font-family: $cui-font-family-tt-norms;
  font-weight: $cui-font-bold;
  font-size: $cui-font-base;
  line-height: $cui-line-height-2xl;
  color: $cookunity-white;

  @include mobile {
    font-size: $cui-font-xs;
  }
}

p.rebrand,
input.rebrand,
.zip-btn-cui.rebrand,
.btn-rebrand-white,
button.rebrand,
li.rebrand {
  font-family: $cui-font-family-tt-norms;
  font-weight: 450;
  font-size: 16px;
  line-height: 24px;

  margin: 0;
}

p.rebrand.bold,
.zip-btn-cui.rebrand {
  font-weight: 700;
}

p.rebrand.upper {
  text-transform: uppercase;
}

input.rebrand {
  font-style: initial;
}

p.rebrand.small.bold {
  font-weight: $cui-font-bold;
  font-size: $cui-font-sm;
  line-height: $cui-line-height-xl;
}

p.rebrand.small.normal {
  font-weight: 450;
}

input.rebrand.small,
p.rebrand.small {
  font-weight: 500;
  font-size: $cui-font-sm;
  line-height: $cui-line-height-xl;
}

p.rebrand.lead {
  font-family: $cui-font-family-tt-norms;
  font-weight: $cui-font-bold;
  font-size: $cui-font-xl;
  line-height: $cui-line-height-2xl;
  color: $cui-black-color;
}

p.rebrand.more-small {
  font-size: 14px;
  line-height: 20px;
}

a.rebrand.small,
li.rebrand {
  font-family: $cui-font-family-tt-norms;
  font-weight: $cui-font-bold;
  font-size: $cui-font-sm;
  line-height: $cui-line-height-xl;
}

.avallon {
  font-family: $cui-font-family-avallon;
  font-weight: $cui-font-normal;
  font-size: $cui-font-6xl;
  line-height: $cui-line-height-8xl;
  margin: 0;
}

h2.rebrand span.avallon {
  font-weight: $cui-font-normal;
  font-size: 100px;
  line-height: 57px;

  @include mobile {
    font-size: 50px;
    line-height: 44px;
  }
}

a.rebrand {
  font-family: $cui-font-family-tt-norms;
  font-weight: 450;
  font-size: $cui-font-3xl;
  line-height: $cui-line-height-4xl;
}

.container-cui-row {
  display: flex;

  @include mobile {
    flex-direction: column;
  }
}

.container-cui-row.wrap {
  flex-wrap: wrap;
}

.btn-rebrand-white {
  border: 1px solid $cui-black-color;
  border-radius: 66px;
  font-weight: 700;
  background: $cookunity-white;
  color: $cui-black-color;
}

.container-cui {
  margin: 0 auto;
  width: 100%;
  max-width: 1440px;

  .full-image {
    margin: 0 auto;
    max-width: 1065px;
    width: 100%;

    @include mobile {
      object-fit: cover;
      width: 100%;
      height: 200px;
      max-width: 100%;
    }
  }
}

.link-cui {
  -webkit-text-decoration-line: underline;
  text-decoration-line: underline;
  color: $cui-black-color;
  display: flex;
  justify-content: center;
  background-color: $cookunity-white;
  padding: 0;
}

.cms-cui-link {
  height: 47px;
}

.promo-text {
  text-transform: lowercase;

  @include mobile {
    margin: 8px 0;
  }

  span {
    font-weight: bold;
  }

  &::first-letter {
    text-transform: uppercase;
  }
}

p.rebrand.promo-text::first-letter {
  text-transform: uppercase;
}

.container-button-bottom {
  text-align: center;
  margin-top: 50px;

  .cui-link.terciary {
    padding: unset;
    border: none;
  }
}

.green-tag {
  background: $cui-olive-green-color-500;
  box-shadow: 0px 2px 8px rgba(35, 31, 32, 0.1);
  border-radius: 4px;
  padding: 5.5px 8px;
  color: $cookunity-white;
  font-family: $cui-font-family-tt-norms;
  font-style: normal;
  font-weight: 700;
  font-size: 10px;
  line-height: 14px;
  display: flex;
  align-items: center;
  text-align: center;
  text-transform: uppercase;
  width: fit-content;
  margin: 0 auto 17px;
}

.wine-tag {
  background: $cui-wine-color-500;
  box-shadow: 0px 2px 8px rgba(35, 31, 32, 0.1);
  border-radius: 4px;
  padding: 5.5px 8px;
  color: $cookunity-white;
  font-family: $cui-font-family-tt-norms;
  font-style: normal;
  font-weight: 700;
  font-size: 10px;
  line-height: 14px;
  display: flex;
  align-items: center;
  text-align: center;
  text-transform: uppercase;
  justify-content: center;
  margin: 0;
}

.is-black-mode {
  background-color: $cui-black-color-600;

  form .input-button .container-input {
    input:-webkit-autofill,
    input:-webkit-autofill:focus {
      transition: background-color 600000s 0s, color 600000s 0s;
    }

    input {
      color: $cookunity-white;
    }

    input::-webkit-input-placeholder {
      color: $cookunity-white;
    }
  }
}

p.center-section {
  max-width: 60%;
  width: 100%;
  margin: -20px auto 20px;
  text-align: center;

  @include mobile {
    max-width: 90%;
  }
}

.align-center {
  margin: 0px auto;
  text-align: center;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
}

.rossanova-title {
  text-align: center;
  margin-top: 68px;

  h2 {
    font-family: $cui-font-family-rossanova;
    font-size: 28px;
    font-style: normal;
    font-weight: 500;
    line-height: 32px;
    margin: 0;
  }

  p {
    color: $cui-black-color-300;
    margin: 7px 0 20px;

    @include mobile {
      width: 90%;
      margin: 0 auto;
    }
  }
}

.container-cui.simple-title {
  text-align: center;
  margin-bottom: 30px;
  margin-top: 96px;

  @include mobile {
    padding: 0 20px;
  }

  h2 {
    font-family: $cui-font-family-rossanova;
    font-size: 28px;
    font-style: normal;
    font-weight: 400;
    line-height: 36px;
    margin: 0 7px 0 0;
  }
  p {
    font-family: $cui-font-family-tt-norms;
    font-size: 16px;
    font-style: normal;
    font-weight: 450;
    line-height: 24px;
    margin: 0;
  }
}
